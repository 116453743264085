<template>
    <N2FAddEdit apiName="getN2F"  :idN2F="state.N2FAddEdit" :isOpen="state.openCloseN2FAddEdit" :key="state.openCloseN2FAddEdit" @closeN2FAddEdit="state.openCloseN2FAddEdit = 0"/>    
</template>
      
<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import N2FAddEdit from "./N2FAddEdit.vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "General",
    components : {
        N2FAddEdit,
    },
    setup() {

        const router = useRouter();

        const state = reactive({
            N2FAddEdit : 0 as any,
            openCloseN2FAddEdit : 0,
        });

        onMounted(async() => {
            if (router.currentRoute.value.params.id) {
                state.N2FAddEdit = router.currentRoute.value.params.id;
                state.openCloseN2FAddEdit = 1;
            } 
        });
            
        return {
            state,
        };
    },
});
</script>